<template>
  <v-autocomplete
    label="Proveedores"
    placeholder="Seleccione un proveedor"
    :loading="loading"
    :items="items"
    item-text="_label"
    item-value="code"
    return-object
    v-bind:value="value"
    :rules="onlyRequired"
    v-on:input="$emit('input', $event)"
    :search-input.sync="search"
    ref="auto"
  >
  </v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    isProvider: { type: Boolean, default: true },
    business: { type: Number, required: true },
    reset: { type: Boolean, required: false },
    value: [Object],
    reload: [Boolean],
  },
  data: () => ({
    loading: false,
    items: [],
    onlyRequired: [rules.required],
    search: "",
  }),
  methods: {
    getProviderCompanies(business) {
      if (business == null || business == undefined) {
        return;
      }
      this.loading = true;
      let uri = "/api/accounting/company-provider/?business=" + business;
      if (this.search) {
        uri += "&search=" + this.search;
      }
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.items = res.data.results.map((item) => {
            return {
              _label: item.provider.nit + " - " + item.provider.name,
              code: item.provider.nit,
            };
          });
        } else {
          console.log(res);
        }
      });
      this.loading = false;
    },
    getCustomerCompany(business) {
      if (business == null || business == undefined) {
        return;
      }
      this.loading = true;
      let uri = "/api/accounting/company-customer/?business=" + business;
      if (this.search) {
        uri += "&search=" + this.search;
      }
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.items = res.data.results.map((item) => {
            return {
              _label: item.customer.nit + " - " + item.customer.name,
              code: item.customer.nit,
            };
          });
        } else {
          console.log(res);
        }
      });
      this.loading = false;
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
    reload: function () {
      if (this.isProvider) {
        this.getProviderCompanies(this.business);
      } else {
        this.getCustomerCompany(this.business);
      }
    },
    search: function (oldVal, newVal) {
      if (newVal == null || newVal == undefined || newVal <= 0) {
        return;
      }
      if (this.isProvider) {
        this.getProviderCompanies(this.business);
      } else {
        this.getCustomerCompany(this.business);
      }
    },
    business: function () {
      if (this.business == null || this.business == undefined || this.business <= 0) {
        return;
      }
      if (this.isProvider) {
        this.getProviderCompanies(this.business);
      } else {
        this.getCustomerCompany(this.business);
      }
    },
    isProvider(val) {
      if (val) {
        this.getProviderCompanies(this.business);
      } else {
        this.getCustomerCompany(this.business);
      }
    },
  },
};
</script>
